import React, { useState } from 'react';
import './StampGame.css';

import Material from './Material';
import Piece from './Piece';

import blueCounter from '../images/stamp-game/counter-blue.png';
import greenCounter from '../images/stamp-game/counter-green.png';
import redCounter from '../images/stamp-game/counter-red.png';

import stamp1 from '../images/stamp-game/1.png';
import stamp10 from '../images/stamp-game/10.png';
import stamp100 from '../images/stamp-game/100.png';
import stamp1000 from '../images/stamp-game/1000.png';

import blueSkittle from '../images/stamp-game/skittle-blue.png';
import greenSkittle from '../images/stamp-game/skittle-green.png';
import redSkittle from '../images/stamp-game/skittle-red.png';

let counterImages = { blue: blueCounter, green: greenCounter, red: redCounter };
let skittleImages = { blue: blueSkittle, green: greenSkittle, red: redSkittle };


const StampGame = () => {
  const [counterCounts, setCounterCounts] = useState({ blue: 1, green: 1, red: 1 });
  const [skittleCounts, setSkittleCounts] = useState({ blue: 1, green: 1, red: 1 });

  const [unitStamps, setUnitStamps] = useState(1);
  const [tenStamps, setTenStamps] = useState(1);
  const [hundredStamps, setHundredStamps] = useState(1);
  const [thousandStamps, setThousandStamps] = useState(1);

  const maybeIncrementCounters = (color) => {
    setCounterCounts({...counterCounts, [color]: counterCounts[color] + 1});
  };

  const maybeIncrementSkittles = (color) => {
    setSkittleCounts({...skittleCounts, [color]: skittleCounts[color] + 1});
  };

  const renderUnitStamps = () => {
    let stamps = [];
    for (let i = 0; i < unitStamps; i++) {
      stamps.push(
        <Piece
          src={stamp1}
          name="1"
          key={i} 
          onStop={() => setUnitStamps(unitStamps + 1)}
        />
      );
    }
    return stamps;
  };

  const renderTenStamps = () => {
    let stamps = [];
    for (let i = 0; i < tenStamps; i++) {
      stamps.push(
        <Piece
          src={stamp10}
          name="10"
          key={i} 
          onStop={() => setTenStamps(tenStamps + 1)}
        />
      );
    }
    return stamps;
  };

  const renderHundredStamps = () => {
    let stamps = [];
    for (let i = 0; i < hundredStamps; i++) {
      stamps.push(
        <Piece
          src={stamp100}
          name="100"
          key={i} 
          onStop={() => setHundredStamps(hundredStamps + 1)}
        />
      );
    }
    return stamps;
  };

  const renderThousandStamps = () => {
    let stamps = [];
    for (let i = 0; i < thousandStamps; i++) {
      stamps.push(
        <Piece
          src={stamp1000}
          name="1000"
          key={i} 
          onStop={() => setThousandStamps(thousandStamps + 1)}
        />
      );
    }
    return stamps;
  };

  const renderCounters = (color) => {
    let counters = [];
    for (let i = 0; i < counterCounts[color]; i++) {
      counters.push(
        <Piece
          src={counterImages[color]}
          name={`${color} counter`}
          key={i} 
          onStop={() => maybeIncrementCounters(color)}
        />
      );
    }
    return counters;
  };

  const renderSkittles = (color) => {
    let skittles = [];
    for (let i = 0; i < skittleCounts[color]; i++) {
      skittles.push(
        <Piece
          src={skittleImages[color]}
          name={`${color} skittle`}
          key={i} 
          onStop={() => maybeIncrementSkittles(color)}
        />
      );
    }
    return skittles;
  };

  return (
    <Material>
      <div id="stamp-game">
        <div id="stamp-game__box">
          <div id="stamp-game__skittles">
            <div>
              {renderSkittles('red')}
            </div>
            <div>
              {renderSkittles('blue')}
            </div>
            <div>
              {renderSkittles('green')}
            </div>
          </div>
          <div id="stamp-game__counters">
            <div>
              {renderCounters('red')}
            </div>
            <div>
              {renderCounters('blue')}
            </div>
            <div>
              {renderCounters('green')}
            </div>
          </div>
          <div className="stamp-game__stamps">
            {renderThousandStamps()}
          </div>
          <div className="stamp-game__stamps">
            {renderHundredStamps()}
          </div>
          <div className="stamp-game__stamps">
            {renderTenStamps()}
          </div>
          <div className="stamp-game__stamps">
            {renderUnitStamps()}
          </div>
        </div>
      </div>
    </Material>
  );
};

export default StampGame;

