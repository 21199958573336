import React, { useState } from 'react';
import Draggable from 'react-draggable';

const Beads = ({ size, onStop }) => {
  const [moved, setMoved] = useState(false);

  const handleStopped = (e, data) => {
    // Only call onStop once
    !moved && onStop && onStop();
    setMoved(true);
  };

  return (
    <Draggable onStop={handleStopped}>
      <div className={`golden-beads golden-beads-${size} ${moved ? 'moved' : ''}`}></div>
    </Draggable>
  );
};

export default Beads;
