import React, { useState } from 'react';

import './GoldenBeads.css';

import Material from './Material';
import Beads from './Beads';


const GoldenBeads = () => {
  const [beadCounts, setBeadCounts] = useState({ '1': 1, '10': 1, '100': 1, '1000': 1 });

  const maybeIncrementBeadCount = (color) => {
    setBeadCounts({...beadCounts, [color]: beadCounts[color] + 1});
  };

  const renderBeads = (color) => {
    let beads = [];
    for (let i = 0; i < beadCounts[color]; i++) {
      beads.push(
        <Beads size={color} key={i} onStop={() => maybeIncrementBeadCount(color)} />
      );
    }
    return beads;
  };

  return (
    <Material>
      <div id="golden-beads">
        <div id="golden-beads__holder">
          <div>
            {renderBeads('1000')}
          </div>
          <div>
            {renderBeads('100')}
          </div>
          <div>
            {renderBeads('10')}
          </div>
          <div>
            {renderBeads('1')}
          </div>
        </div>
      </div>
    </Material>
  );
};

export default GoldenBeads;

