import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';
import { GoldenBeads } from './components';
import { HomePage } from './components';
import { PegBoard } from './components';
import { StampGame } from './components';
import './App.css';


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/peg-board">
          <PegBoard />
        </Route>
        <Route path="/stamp-game">
          <StampGame />
        </Route>
        <Route path="/golden-beads">
          <GoldenBeads />
        </Route>
        <Route path="/">
          <HomePage />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
